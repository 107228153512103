import http from './http'

export const getEntities = (entityName, searchString) => {
  return http.$get(`/crm/search/${entityName}`, {
    params: {
      query: searchString,
    },
  })
}

function getAPIToken() {
  const env = localStorage.getItem('env')

  if (env === 'qa') {
    return `token:${process.env.VUE_APP_QA_API_TOKEN}`
  } else if (env === 'local') {
    return `token:${process.env.VUE_APP_LOCAL_API_TOKEN}`
  } else if (env === 'demo') {
    return `token:${process.env.VUE_APP_DEMO_API_TOKEN}`
  } else if (env === 'heroku-demo') {
    return `token:${process.env.VUE_APP_HEROKU_DEMO_API_TOKEN}`
  } else if (env === 'doordash') {
    return `token:${process.env.VUE_APP_DD_API_TOKEN}`
  }
}

export const getTotpSecretKey = () => {
  return http
    .$get('/settings/otp_key/', {
      headers: {
        Authorization: getAPIToken(),
      },
    })
    .then((response) => {
      return {
        totpSecretKeyId: response.id,
        totpSecretKey: response.value,
      }
    })
}

export const setTotpSecretKey = (id, secret) => {
  return http.$patch(
    '/settings/otp_key/',
    {
      id,
      value: secret,
    },
    {
      headers: {
        Authorization: getAPIToken(),
      },
    }
  )
}
