<template>
  <v-app>
    <DevAuthMessageDialog
      v-if="isAuthDialogVisible"
      @next="openSelectFeatureDialog"
    />
  </v-app>
</template>

<script>
import DevAuthMessageDialog from './components/dev/DevAuthMessageDialog'

export default {
  components: {
    DevAuthMessageDialog,
  },
  data() {
    return {
      authJson: undefined,
      isAuthDialogVisible: false,
    }
  },
  created() {
    const authString = localStorage.getItem('authJson')

    if (authString) {
      this.authJson = JSON.parse(authString)
      window.postMessage(this.authJson, '*')
    } else {
      this.isAuthDialogVisible = true
    }
  },
  methods: {
    openSelectFeatureDialog(authJson) {
      this.authJson = authJson

      window.postMessage(this.authJson, '*')

      this.isAuthDialogVisible = false
    },
  },
}
</script>
