<template>
  <v-dialog persistent :value="true" width="100%" max-width="500">
    <v-card>
      <v-card-title class="text-h5 ap-orange ap-white--text">
        Authentication
      </v-card-title>

      <v-card-text>
        <v-radio-group v-model="authJson.systemType" mandatory>
          <template #label>
            <div>System Type</div>
          </template>
          <v-radio
            v-for="systemType in systemTypes"
            :key="systemType.value"
            :label="systemType.label"
            :value="systemType.value"
          />
        </v-radio-group>

        <v-select
          v-model="environmentSelected"
          dense
          outlined
          :items="environments"
          item-text="label"
          label="Environment"
          return-object
          @change="selectFirstUser"
        />

        <v-select
          v-model="userSelected"
          dense
          outlined
          :items="usersByEnvironment"
          item-text="userName"
          label="User"
          return-object
          hide-details
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-switch
          v-model="isNewDupeGridEnabled"
          label="Use new DupeGrid"
          hide-details
          class="mt-0"
        />
        <v-spacer />
        <v-btn color="ap-orange" text @click="onNext"> Next </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { totp } from 'otplib'
import { getTotpSecretKey } from '@/api/dev'

export default {
  data() {
    return {
      userSelected: null,
      environmentSelected: null,
      authJson: {
        env: '',
        systemType: 'sfdc',
        agentId: '737561b4-bc8f-11ea-bb1a-f40f241f8cc3',
        userId: '',
        orgId: '',
        sessionId: '',
        origin: 'https://qaap1-dev-ed.lightning.force.com',
        url: 'https://wwww.google.com',
        email: '',
        userName: '',
        firstName: '',
        lastName: '',
        permissionName: '',
        data: {
          entityKey: '0013t00002KlhmiAAB', // duplicate
          // entityKey: '0013t00001pK2L6AAK', // no duplicates
          entityName: 'Account',
        },
      },
      usersQA: [
        {
          userId: '0053t000007KnOlAAK',
          userName: 'qaap1@activeprime.com',
          email: 'dev@activeprime.com',
          firstName: 'qaap1',
          lastName: 'qaap2',
          permissionName: 'ActivePrime_Admin',
        },
        {
          userId: '0053t000008st0xAAA',
          userName: 'sales.rep@activeprime.com',
          email: 'dev@activeprime.com',
          firstName: 'Sales Rep',
          lastName: 'Sales Rep',
          permissionName: 'ActivePrime_Sales_Rep',
        },
      ],
      usersLocal: [],
      usersDemo: [
        {
          userId: '0055f000004NJhlAAG',
          userName: 'demo@activeprime.com',
          email: 'dev@activeprime.com',
          firstName: 'Client',
          lastName: 'Demos',
          permissionName: 'ActivePrime_Admin',
        },
      ],
      usersHerokuDemo: [
        {
          userId: '0058c000008ikWOAAY',
          userName: 'dev+admin_user@activeprime.com',
          email: 'dev+admin_user@activeprime.com',
          firstName: 'Demo',
          lastName: 'User',
          permissionName: 'ActivePrime_Admin',
        },
      ],
      systemTypes: [
        {
          label: 'Salesforce',
          value: 'sfdc',
        },
        {
          label: 'Microsoft Dynamics 365',
          value: 'msdcrm',
        },
      ],
      permissions: [
        {
          label: 'Admin',
          value: 'ActivePrime_Admin',
        },
        {
          label: 'Sub Admin',
          value: 'ActivePrime_Sub_Admin',
        },
        {
          label: 'Sales Rep',
          value: 'ActivePrime_Sales_Rep',
        },
        {
          label: 'Guest',
          value: 'ActivePrime_Guest',
        },
      ],
      isNewDupeGridEnabled: true,
    }
  },
  computed: {
    environments() {
      return process.env.NODE_ENV === 'development'
        ? [
            {
              label: 'QA',
              value: 'qa',
              users: this.usersQA,
            },
            {
              label: 'Local',
              value: 'local',
              users: this.usersLocal,
            },
            {
              label: 'Heroku Demo',
              value: 'heroku-demo',
              users: this.usersHerokuDemo,
            },
          ]
        : [
            {
              label: 'QA',
              value: 'qa',
              users: this.usersQA,
            },
            {
              label: 'Demo',
              value: 'demo',
              users: this.usersDemo,
            },
            {
              label: 'Heroku Demo',
              value: 'heroku-demo',
              users: this.usersHerokuDemo,
            },
            {
              label: 'DoorDash',
              value: 'doordash',
              users: [
                {
                  userId: '0052L000003tBOvQAM',
                  userName: 'apuser@doordash.com.uat',
                  email: 'biz-apps-integrations@doordash.com',
                  firstName: 'Active Prime',
                  lastName: 'Integration User',
                  permissionName: 'ActivePrime_Admin',
                },
              ],
            },
            // {
            //   label: 'DR',
            //   value: 'dr',
            //   users: this.usersQA,
            // },
          ]
    },
    usersByEnvironment() {
      return this.environmentSelected.users
    },
  },
  created() {
    try {
      this.usersLocal = require('./localUsers.json')
    } catch {
      console.log('localUsers.json not found')
    } finally {
      this.environmentSelected = this.environments[0]
      this.userSelected = this.environmentSelected.users[0]
    }
  },
  methods: {
    selectFirstUser() {
      this.userSelected = this.environmentSelected.users[0]
    },
    async onNext() {
      this.authJson = {
        ...this.authJson,
        env: this.environmentSelected.value,
        ...this.userSelected,
      }

      localStorage.setItem('env', this.authJson.env)
      localStorage.setItem('is_new_dupegrid_enabled', this.isNewDupeGridEnabled)
      localStorage.setItem('authJson', JSON.stringify(this.authJson))

      const { totpSecretKeyId, totpSecretKey } = await getTotpSecretKey()

      this.authJson = {
        ...this.authJson,
        totpSecretKeyId,
        totp: totp.generate(totpSecretKey),
      }
      localStorage.setItem('authJson', JSON.stringify(this.authJson))

      this.$emit('next', {
        ...this.authJson,
        isNewDupeGridEnabled: this.isNewDupeGridEnabled,
      })
    },
  },
}
</script>
